import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { motion } from 'framer-motion';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isSessionExpired } = useAuth();

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  useEffect(() => {
    if (isSessionExpired) {
      setError('Your session has expired. Please log in again.');
    }
  }, [isSessionExpired]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      await login(email, password);
      navigate('/beyondDSA');
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        switch (errorData.error) {
          case 'EMAIL_NOT_FOUND':
            setError('This email is not registered. Would you like to sign up?');
            break;
          case 'INVALID_CREDENTIALS':
            setError('Invalid email or password. Please try again.');
            break;
          default:
            setError(errorData.message || 'An error occurred during login');
        }
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const welcomeVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.1
      }
    }
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-10 py-12">
            <motion.h2 
              className="text-3xl font-light text-gray-800 text-center mb-8"
              variants={welcomeVariants}
              initial="hidden"
              animate="visible"
            >
              {"Welcome back to".split('').map((letter, index) => (
                <motion.span key={index} variants={letterVariants} className="inline-block">
                  {letter === ' ' ? '\u00A0' : letter}
                </motion.span>
              ))}
              <br />
              <span className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-500">
                {"CodingBeyondDSA".split('').map((letter, index) => (
                  <motion.span key={index} variants={letterVariants} className="inline-block">
                    {letter}
                  </motion.span>
                ))}
              </span>
            </motion.h2>
            {message && (
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-green-500 text-sm mb-4 text-center"
              >
                {message}
              </motion.p>
            )}
            {error && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 text-sm mb-4 text-center"
              >
                <p>{error}</p>
                {error === 'This email is not registered. Would you like to sign up?' && (
                  <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500 transition duration-200">
                    Sign up here
                  </Link>
                )}
              </motion.div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <Link to="/forgot-credentials" className="font-medium text-gray-600 hover:text-gray-500">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200"
                >
                  Log In
                </motion.button>
              </div>
            </form>
          </div>
          <div className="px-10 py-6 bg-gray-50 border-t border-gray-200">
            <p className="text-sm text-center text-gray-600">
              Don't have an account?{' '}
              <Link to="/signup" className="font-medium text-gray-700 hover:text-gray-500 transition duration-200">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;