import React from 'react';
import { Book, Code, Users, Zap, ChevronRight } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AboutPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-center">Getting Started with CodingBeyondDSA</h1>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Welcome to Early Access!</h2>
        <p className="mb-4">
          Thank you for joining us on this exciting journey to go beyond basic Data Structures and Algorithms. As an early access member, you're at the forefront of our mission to create comprehensive, real-world focused software engineering content.
        </p>
        <p className="mb-4">
          While we're still developing much of our content, here's how you can make the most of your early access:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Explore our <strong>DSA Cheatsheets</strong> - These are ready for you to use and master fundamental concepts.</li>
          <li>Stay tuned for <strong>regular updates</strong> as we roll out new topics and features.</li>
          <li>Provide <strong>feedback</strong> to help shape the platform - your input is invaluable!</li>
          <li><strong>Connect with fellow learners</strong> in our community forums (coming soon).</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">What to Expect</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FeatureCard 
            icon={Book}
            title="Comprehensive Learning Resources"
            description="In-depth guides on advanced software engineering concepts, from system design to optimization techniques."
          />
          <FeatureCard 
            icon={Code}
            title="Practical Coding Challenges"
            description="Real-world coding problems to reinforce your understanding and prepare you for technical interviews."
          />
          <FeatureCard 
            icon={Users}
            title="Expert Insights"
            description="Learn from industry professionals through our upcoming expert talk series and case studies."
          />
          <FeatureCard 
            icon={Zap}
            title="Beyond DSA"
            description="Explore topics that go beyond traditional DSA, focusing on skills crucial for modern software engineering."
          />
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">How to Navigate the Platform</h2>
        <ol className="list-decimal pl-6 space-y-4">
          <li>
            <strong>Explore Topics:</strong> Use the dashboard to browse through our growing list of topics. Even if content isn't available yet, you can see what's coming!
          </li>
          <li>
            <strong>DSA Cheatsheets:</strong> Start with our comprehensive DSA cheatsheets. These are ready for you to use and will form the foundation for more advanced topics.
          </li>
          <li>
            <strong>Check for Updates:</strong> We're constantly working on new content. Make sure to log in regularly to see what's new.
          </li>
          <li>
            <strong>Engage with the Community:</strong> Once our forums are live, participate in discussions, ask questions, and share your insights with fellow learners.
          </li>
        </ol>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Your Early Access Advantage</h2>
        <p className="mb-4">
          As an early access member, you're not just getting a sneak peek - you're helping shape the future of CodingBeyondDSA. Your feedback and engagement will directly influence the content we create and the features we develop.
        </p>
        <p className="mb-4">
          Remember, software engineering is a journey of continuous learning. As we grow, you'll have front-row access to cutting-edge content designed to keep you at the forefront of the field.
        </p>
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded">
          <p className="font-bold">Ready to start?</p>
          <p>Head to the Dashboard and start exploring our topics. Even if a topic doesn't have content yet, you can see what's in the pipeline!</p>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;