import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';

const PricingPage = ({ isSubscribed, hiddenValues }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => setRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      const script = document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handleSubscribe = async () => {
    if (isSubscribed) {
      alert('You are already subscribed!');
      return;
    }

    if (!razorpayLoaded) {
      console.error('Razorpay not loaded');
      alert('Payment system is not ready. Please try again later.');
      return;
    }

    try {
      const response = await axios.post(
        'https://codingbeyonddsa-backend-production.up.railway.app/api/payment/create-order',
        null,
        {
          params: {
            email: user.email
          }
        }
      );
      const { orderId, amount, currency, email } = response.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: 'CodingBeyondDSA',
        description: 'Lifetime Pro Developer Subscription',
        order_id: orderId,
        handler: async function (response) {
          try {
            const verificationResponse = await axios.post('https://codingbeyonddsa-backend-production.up.railway.app/api/payment/verify-payment', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              email: email
            });

            if (verificationResponse.data === "Payment successful!") {
              alert('Payment successful! You now have lifetime access.');
              navigate('/dashboard');
            } else {
              alert('Payment verification failed. Please contact support.');
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            alert('An error occurred during payment verification. Please contact support.');
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.contact
        },
        theme: {
          color: '#3B82F6'
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', function (response){
        console.error('Payment failed:', response.error);
        alert('Payment failed. Please try again.');
      });
      paymentObject.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('An error occurred while initializing payment. Please try again.');
    }
  };

  if (isSubscribed) {
    return (
      <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-6">You're already subscribed!</h2>
          <p className="text-xl text-center text-gray-600 mb-12">
            Thank you for being a lifetime member. Enjoy your access to all our premium content.
          </p>
          <button
            onClick={() => navigate('/dashboard')}
            className="bg-blue-600 text-white py-3 px-6 rounded-lg mx-auto block hover:bg-blue-700 transition-colors"
          >
            Go to Dashboard
          </button>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-6">Early Access Offer: ₹999</h2>
        <p className="text-xl text-center text-gray-600 mb-12">
          Join our growing community of developers. Access our current content library and future updates to help enhance your engineering skills.
        </p>

        <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
          <div className="space-y-4">
            <p className="text-lg font-semibold">Logged in as: {user.email}</p>
            <p className="text-sm text-gray-600">
              Note: We're continuously developing new content. Your subscription includes access to all current materials and future updates within the scope of this offer.
            </p>
            <button
              onClick={handleSubscribe}
              className="bg-blue-600 text-white py-3 px-6 rounded-lg w-full mt-4 hover:bg-blue-700 transition-colors"
            >
              Get Access Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPage;