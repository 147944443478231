import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Lock, ChevronDown, ChevronUp, Book } from 'lucide-react';
import { useAuth } from '../auth/AuthContext';
import { Link } from 'react-router-dom';

const API_BASE_URL = 'https://codingbeyonddsa-backend-production.up.railway.app/api';

const StackItem = ({ title, description, isOpen, toggleOpen, isLocked, index }) => (
  <div className="border-b border-gray-200 last:border-b-0">
    <button
      className="w-full px-4 py-2 text-left flex justify-between items-center hover:bg-gray-50 transition-colors duration-150"
      onClick={toggleOpen}
    >
      <span className="font-medium text-blue-600">{title}</span>
      {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
    </button>
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`px-4 py-2 bg-gray-50 ${isLocked && index > 0 ? 'blur-sm' : ''}`}
        >
          <div 
            className="text-sm text-gray-600"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);

const StackList = ({ items, title, isLocked }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="bg-white rounded-md shadow-md border border-gray-200 overflow-hidden relative">
      <h3 className="text-lg font-semibold p-4 bg-gray-100 text-black">{title}</h3>
      <div>
        {items.map((item, index) => (
          <StackItem
            key={index}
            title={item.title}
            description={item.description}
            isOpen={openIndex === index}
            toggleOpen={() => setOpenIndex(openIndex === index ? null : index)}
            isLocked={isLocked}
            index={index}
          />
        ))}
      </div>
      {isLocked && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <Lock className="text-gray-600" size={32} />
        </div>
      )}
    </div>
  );
};

const HiddenContent = ({ onPurchase, isSignedIn }) => (
  <div className="flex items-center justify-center h-full">
    <div className="text-center bg-white p-6 rounded-lg shadow-lg">
      <Lock className="mx-auto mb-2 text-gray-600" size={32} />
      <p className="text-gray-800 font-semibold mb-2">This content is locked</p>
      {isSignedIn ? (
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          onClick={onPurchase}
        >
          Purchase Subscription
        </button>
      ) : (
        <p className="text-sm text-gray-600 mb-2">Please sign in to purchase a subscription</p>
      )}
    </div>
  </div>
);

const DSACheatSheet = () => {
  const { user } = useAuth();
  const [topicsList, setTopicsList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTab, setSelectedTab] = useState('theory');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    fetchTopicsList();
    if (user && user.email) {
      fetchUserProfile(user.email);
    }
  }, [user]);

  const fetchUserProfile = async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/profile/${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }
      const data = await response.json();
      setUserProfile(data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const fetchTopicsList = async () => {
    try {
      console.log('Fetching topics list...');
      const response = await fetch(`${API_BASE_URL}/dsa/titles`);
      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched topics:', data);
      setTopicsList(data);
      if (data.length > 0) {
        handleTopicSelection(data[0]);
      } else {
        setLoading(false);
        setError('No topics available.');
      }
    } catch (error) {
      console.error('Error fetching topics list:', error);
      setError(`Error fetching topics list: ${error.message}`);
      setLoading(false);
    }
  };

  const isContentLocked = (topicTitle) => {
    const topic = topicsList.find(t => t.title === topicTitle);
    return topic && topic.hidden_key === 'Y' && (!userProfile || userProfile.subscription === false);
  };

  const handleTopicSelection = async (topicTitle) => {
    setLoading(true);
    if (isContentLocked(topicTitle)) {
      setSelectedTopic({
        title: topicTitle,
        hidden_key: 'Y',
        keyConcepts: ['This content is locked'],
        problems: [{ title: 'Locked Problem', description: 'This problem is locked' }],
        caseStudies: [{ title: 'Locked Algorithm', description: 'This algorithm is locked' }]
      });
      setLoading(false);
    } else {
      try {
        console.log(`Fetching details for topic: ${topicTitle}`);
        const response = await fetch(`${API_BASE_URL}/dsa/${encodeURIComponent(topicTitle)}`);
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched topic details:', data);
        setSelectedTopic(data);
      } catch (error) {
        console.error('Error fetching topic details:', error);
        setError(`Error fetching topic details: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePurchase = () => {
    console.log('Purchase functionality to be implemented');
    window.location.href = '/pricing';
  };

  const renderContent = () => {
    if (loading) {
      return <div className="text-center text-gray-600">Loading...</div>;
    }

    if (error) {
      return (
        <div className="text-center text-red-500">
          <p>{error}</p>
          <button 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={fetchTopicsList}
          >
            Retry
          </button>
        </div>
      );
    }

    if (!selectedTopic) {
      return <div className="text-center text-gray-600">No topic selected</div>;
    }

    const isLocked = selectedTopic.hidden_key === 'Y';

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={selectedTab}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          className="space-y-2 relative"
        >
          <h2 className="text-2xl font-bold text-black">{selectedTopic.title}</h2>
          
          <div className="flex space-x-2 mb-4">
            {['theory', 'problems', 'algorithms'].map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-md transition duration-300 text-base font-medium ${
                  selectedTab === tab 
                    ? 'bg-gray-800 text-white shadow-sm' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>

          <div className="relative">
            {isLocked ? (
              <HiddenContent onPurchase={handlePurchase} isSignedIn={!!user} />
            ) : (
              <>
                {selectedTab === 'theory' && (
                  <div className="text-gray-600 leading-relaxed bg-white p-4 rounded-md shadow-md border border-gray-200">
                    {selectedTopic.keyConcepts && selectedTopic.keyConcepts.length > 0 && (
                      <>
                        <h3 className="text-lg font-semibold text-black mb-1">Key Concepts</h3>
                        <div className="space-y-1">
                          {selectedTopic.keyConcepts.map((concept, index) => (
                            <div 
                              key={index} 
                              className="text-sm"
                              dangerouslySetInnerHTML={{ __html: concept }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {selectedTab === 'problems' && (
                  <StackList items={selectedTopic.problems} title="Coding Problems" isLocked={false} />
                )}
                {selectedTab === 'algorithms' && selectedTopic.caseStudies && selectedTopic.caseStudies.length > 0 && (
                  <StackList items={selectedTopic.caseStudies} title="Algorithms" isLocked={false} />
                )}
              </>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Panel (Static) */}
      <div className="w-72 bg-white shadow-md p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 flex items-center text-black">
          <Book size={24} className="mr-2" />
          Topics
        </h2>
        <div className="space-y-2">
          {topicsList.map((topic, index) => (
            <button
              key={index}
              className={`w-full text-left p-3 rounded-md transition duration-300 ${
                selectedTopic && selectedTopic.title === topic 
                  ? 'bg-gray-200 text-black' 
                  : 'hover:bg-gray-100 text-gray-600'
              }`}
              onClick={() => {
                handleTopicSelection(topic);
                setSelectedTab('theory');
              }}
            >
              <div className="flex items-center justify-between">
                <span className="font-medium">{topic}</span>
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Right Content Area */}
      <div className="flex-1 p-6 overflow-y-auto bg-gray-50">
        {renderContent()}
      </div>
    </div>
  );
};

export default DSACheatSheet;