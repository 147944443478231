import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const ForgotCredentials = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      // Here you would typically make an API call to initiate the password reset process
      // For this example, we'll just simulate a successful request
      await new Promise(resolve => setTimeout(resolve, 1000));
      setMessage('If an account exists for this email, you will receive instructions to reset your password.');
    } catch (error) {
      setError('An unexpected error occurred. Please try again later.');
    }
  };

  const formVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={formVariants}
        className="w-full max-w-md"
      >
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-10 py-12">
            <h2 className="text-3xl font-light text-gray-800 text-center mb-8">
              Forgot Your Credentials?
            </h2>
            {message && (
              <p className="text-green-500 text-sm mb-4 text-center">{message}</p>
            )}
            {error && (
              <p className="text-red-500 text-sm mb-4 text-center">{error}</p>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                  required
                />
              </div>
              <div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200"
                >
                  Reset Password
                </motion.button>
              </div>
            </form>
          </div>
          <div className="px-10 py-6 bg-gray-50 border-t border-gray-200">
            <p className="text-sm text-center text-gray-600">
              Remember your credentials?{' '}
              <Link to="/login" className="font-medium text-gray-700 hover:text-gray-500 transition duration-200">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ForgotCredentials;