import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BookOpen, Code, Users, ChevronRight } from 'lucide-react';
import { AuthProvider, useAuth } from './auth/AuthContext';
import ProtectedRoute from './auth/ProtectedRoute';

// Existing components (import statements)
import PricingPage from './pages/PricingPage';
import AboutPage from './pages/AboutPage';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard/MainDashboard';
import UserProfile from './components/UserProfile';
import PaymentPage from './components/Payment';
import DSACheatSheet from './components/DSACheatSheet';
import TwitchStreamPage from './components/ExpertTalk';
import ForgotCredentials from './components/ForgotCredentials';
import StreamPage from './components/StreamPage';

const VideoComponent = ({ className }) => (
  <div className={`relative w-full h-0 pb-[56.25%] rounded-lg overflow-hidden ${className}`}>
    <video 
      className="absolute top-0 left-0 w-full h-full object-cover"
      autoPlay 
      loop
      playsInline
    >
      <source src="/Untitled.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

const FeatureItem = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="w-8 h-8 mt-1 rounded-full flex-shrink-0 bg-gray-100 flex items-center justify-center">
      <Icon size={20} />
    </div>
    <div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <p className="text-lg text-gray-600">{description}</p>
    </div>
  </div>
);

const HomePage = () => {
  const [isHeroVisible, setIsHeroVisible] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsHeroVisible(true);
          observer.unobserve(heroRef.current);
        }
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  const features = [
    {
      icon: BookOpen,
      title: "Comprehensive DSA Cheat Sheets",
      description: "Access our ready-to-use cheat sheets covering key data structures and algorithms for quick reference."
    },
    {
      icon: Code,
      title: "BeyondDSA Topics",
      description: "Explore advanced topics like system design, distributed systems, and more. Perfect for those looking to go beyond basic DSA."
    },
    {
      icon: Users,
      title: "Community Learning",
      description: "Connect with peers and share knowledge as we grow our platform and expert network."
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-6">
      {/* Hero Section */}
      <section ref={heroRef} className="py-24 flex items-center">
        <div className="w-1/2 pr-16">
          <h1 className={`text-6xl font-bold text-black mb-6 transition-all duration-1000 ease-out ${
            isHeroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}>
            Elevate Your Software Engineering Skills
          </h1>
          <p className={`text-2xl text-gray-600 mb-10 transition-all duration-1000 ease-out ${
            isHeroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`} style={{ transitionDelay: '200ms' }}>
            Start with our comprehensive DSA Cheatsheet and grow with us as we expand our BeyondDSA learning resources.
          </p>
          <div className={`bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6 transition-all duration-1000 ease-out ${
            isHeroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`} style={{ transitionDelay: '300ms' }}>
            <p className="font-bold">Early Access Offer:</p>
            <p>Join now to get lifetime access for the price of just one month's subscription! Unlock our DSA Cheatsheet and upcoming BeyondDSA content.</p>
          </div>
          <div className={`flex space-x-6 transition-all duration-1000 ease-out ${
            isHeroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`} style={{ transitionDelay: '400ms' }}>
            <Link
              to="/pricing"
              className="bg-black text-white px-8 py-4 rounded-full text-xl flex items-center"
            >
              Get Lifetime Access Now <ChevronRight className="ml-3" size={24} />
            </Link>
          </div>
        </div>
        <div className="w-1/2">
          <VideoComponent className="w-full h-96" />
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {features.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 text-center">
        <h2 className="text-4xl font-bold mb-8">Ready to start your learning journey beyond DSA?</h2>
        <Link to="/pricing" className="bg-black text-white px-10 py-4 rounded-full inline-block text-xl">Get Lifetime Access</Link>
      </section>
    </div>
  );
};

// Header component
const Header = () => {
  const { user } = useAuth();

  return (
    <header className="bg-white">
      <div className="container mx-auto px-6 py-8 flex justify-between items-center">
        <Link to="/" className="text-3xl font-bold text-black">CodingBeyondDSA</Link>
        <div className="flex items-center space-x-8">
          <nav className="space-x-8">
            <Link to="/beyondDSA" className="text-lg text-gray-600 hover:text-black">BeyondDSA</Link>
            <Link to="/DSACheatSheet" className="text-lg text-gray-600 hover:text-black">DSA Cheatsheet</Link>
            <Link to="/expertTalk" className="text-lg text-gray-600 hover:text-black">Expert Talk</Link>
            <Link to="/about" className="text-lg text-gray-600 hover:text-black">How to Get Started</Link>
            <Link to="/pricing" className="text-lg text-gray-600 hover:text-black">Pricing</Link>
          </nav>
          {user ? (
            <UserProfile />
          ) : (
            <Link 
              to="/login"
              className="bg-black text-white px-6 py-3 rounded-full text-lg"
            >
              Log In
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

// Main App component
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-gray-50">
          <Helmet>
            <title>Coding Beyond DSA</title>
            <link rel="icon" type="image/png" href="/logo192.png" />
          </Helmet>
          <Header />

          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={
                <ProtectedRoute>
                  <PricingPage />
                </ProtectedRoute>
              } />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-credentials" element={<ForgotCredentials />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/expertTalk" element={
                <ProtectedRoute>
                  <StreamPage />
                </ProtectedRoute>
              } />
              <Route path='/dsaCheatSheet' element={
                <ProtectedRoute>
                  <DSACheatSheet/>
                </ProtectedRoute>
              }/>
              <Route path="/beyondDSA" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>

          <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4 text-center">
              <p>&copy; 2024 CodingBeyondDSA. All rights reserved.</p>
            </div>
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;