import React from 'react';
import { BookOpen, Calendar } from 'lucide-react';

const YouTubeEmbed = ({ videoId }) => (
  <div className="aspect-w-16 aspect-h-9 h-[calc(100vh-200px)]">
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="w-full h-full"
    ></iframe>
  </div>
);

const TopicNotes = ({ topic, notes }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden h-[calc(100vh-200px)]">
    <div className="p-4 border-b">
      <h3 className="text-lg font-semibold flex items-center">
        <BookOpen className="mr-2" size={20} />
        {topic} Notes
      </h3>
    </div>
    <div className="p-4 space-y-4 overflow-y-auto h-[calc(100%-64px)]">
      {notes.map((note, index) => (
        <div key={index}>
          <h4 className="font-semibold">{note.title}</h4>
          <p className="text-gray-600">{note.content}</p>
        </div>
      ))}
    </div>
  </div>
);

const StreamPage = () => {
  // Weekly topic data
  const weeklyTopic = {
    date: "May 15, 2024",
    topic: "Java Garbage Collection",
    description: "Join us as we dive deep into Java's Garbage Collection mechanisms!",
    videoId: "YOUR_YOUTUBE_VIDEO_ID", // Replace with actual YouTube video ID
    notes: [
      {
        title: "What is Garbage Collection?",
        content: "Garbage Collection (GC) is the process of automatically freeing memory that is no longer in use by the program. It's a form of automatic memory management in Java that helps developers focus on writing code rather than managing memory allocation and deallocation."
      },
      {
        title: "Types of GC in Java",
        content: "Java has several types of garbage collectors: Serial GC (for single-threaded environments), Parallel GC (for multi-threaded applications), Concurrent Mark Sweep (CMS) GC (for applications requiring low pause times), and G1 GC (Garbage First, for large heap sizes with predictable pause times)."
      },
      {
        title: "How GC Works",
        content: "GC typically works in two main steps: Mark (identify live objects) and Sweep (remove unreferenced objects). Some collectors also include a Compact step to reduce fragmentation. The process involves stopping the application (Stop-The-World pause), although some GC algorithms minimize this pause time."
      },
      {
        title: "GC Tuning",
        content: "GC can be tuned using various JVM flags. Common parameters include heap size (-Xmx, -Xms), generation sizes (-XX:NewRatio, -XX:SurvivorRatio), and GC algorithm selection (-XX:+UseParallelGC, -XX:+UseConcMarkSweepGC, -XX:+UseG1GC). Tuning GC can significantly impact application performance and responsiveness."
      },
      {
        title: "Best Practices",
        content: "To optimize GC: 1) Size your heap appropriately. 2) Choose the right GC algorithm for your application. 3) Minimize object allocation and promote object reuse. 4) Use profiling tools to identify memory leaks and optimize garbage collection. 5) Consider using weak references for caching scenarios."
      }
    ]
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Weekly Expert Talk: {weeklyTopic.topic}</h1>
      <div className="text-lg text-gray-600 mb-4 flex items-center">
        <Calendar className="mr-2" size={20} />
        {weeklyTopic.date}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 space-y-4">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <YouTubeEmbed videoId={weeklyTopic.videoId} />
          </div>
          <div className="bg-white rounded-lg shadow-md overflow-hidden p-4">
            <h2 className="text-xl font-bold">Live Stream: {weeklyTopic.topic}</h2>
            <p className="text-gray-600">{weeklyTopic.description}</p>
          </div>
        </div>
        <div className="col-span-1">
          <TopicNotes topic={weeklyTopic.topic} notes={weeklyTopic.notes} />
        </div>
      </div>
    </div>
  );
};

export default StreamPage;